import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import dayjs from "dayjs";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import moment from "moment-timezone";
import axios from "axios";
import 'dayjs/locale/es';  // Importa el idioma español

dayjs.locale('es');  // Establece el idioma en español
const mapContainerStyle = {
  height: "400px",
  width: "100%"
};
const initialFormData = {
  nombre: "",
  email: "",
  cc: "",
  comentario: "",
  fecha: "",
  jornada: "",
  tipo_servicio: "",
  location: null,
};

const Modal = ({ isOpen, onClose, onSubmit, selectedService }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs().startOf('day'));
  const [blockedDates, setBlockedDates] = useState([]);

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      tipo_servicio: selectedService,
    }));
  }, [selectedService]);

  const getDatesBloqued  = async () => {

    axios.get('https://api.airclean.store/servicio/GetDateBloqued')
    .then(response => {
      setBlockedDates(response.data.data);
    })
    .catch(error => {
      console.error("Error fetching blocked dates: ", error);
    });
  }
  useEffect(() => {
    getDatesBloqued()
  }, [selectedService]);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setFormData((prevFormData) => ({
            ...prevFormData,
            location: {
              lat: latitude,
              lng: longitude
            }
          }));
        },
        (error) => {
          console.error("Error obteniendo la ubicación: ", error);
        }
      );
    } else {
      console.error("Geolocalización no es soportada en este navegador.");
    }
  };

  useEffect(() => {
    getCurrentLocation();
    console.log(blockedDates)
    // Llama a la función con la fecha actual para obtener el arreglo
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setFormData((prevFormData) => ({
      ...prevFormData,
      fecha: formattedDate,
    }));
    setSelectedDate(date);
  };

  const handleMapClick = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    setFormData(initialFormData);  // Limpia el formulario después de enviar
    getDatesBloqued()
  };


  const isJornadaBlocked = (jornada) => {
    return blockedDates.some(blocked => 
      blocked.fecha === formData.fecha && blocked.jornada === jornada
    );
  };

  // const blockedDatesTest = [
  //   dayjs('2024-08-14'),
  //   dayjs('2024-08-15')
  // ];
  

  const getBlockedDates = (dates) => {
    const blockedDatesMap = new Map();
  
    dates.forEach(blocked => {
      const { fecha, jornada } = blocked;
      if (!blockedDatesMap.has(fecha)) {
        blockedDatesMap.set(fecha, new Set());
      }
      blockedDatesMap.get(fecha).add(jornada);
    });
  
    const blockedDatesArray = Array.from(blockedDatesMap.entries())
      .filter(([_, jornadas]) => jornadas.has(1) && jornadas.has(2))
      .map(([fecha]) => dayjs(fecha));
  
    return blockedDatesArray;
  };

  const blockedDatesTest = getBlockedDates(blockedDates);

  

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Solicitar Servicio</DialogTitle>
      <DialogContent>
        <DialogContentText>Llena la información</DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            name="nombre"
            label="Nombre"
            type="text"
            fullWidth
            value={formData.nombre}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            name="cc"
            label="Identificación"
            type="text"
            fullWidth
            value={formData.cc}
            onChange={handleChange}
            required
          />
           <TextField
            margin="dense"
            name="telefono"
            label="Telefono"
            type="text"
            fullWidth
            value={formData.telefono}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            name="comentario"
            label="Direccion (Referencia)"
            type="text"
            fullWidth
            value={formData.comentario}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={formData.email}
            onChange={handleChange}
            required
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Fecha"
              value={selectedDate}
              onChange={handleDateChange}
              minDate={dayjs()}
              shouldDisableDate={(date) => blockedDatesTest.some(blockedDate => date.isSame(blockedDate, 'day'))}

              // shouldDisableDate={disableWeekends}              
              renderInput={(params) => <TextField {...params} fullWidth margin="dense" required />}
            />
          </LocalizationProvider>
          <FormControl fullWidth margin="dense" required>
            <InputLabel>Jornada</InputLabel>
            <Select
              name="jornada"
              value={formData.jornada}
              onChange={handleChange}
              label="Jornada"
            >
              <MenuItem value="1" disabled={isJornadaBlocked(1)}>Matutina</MenuItem>
              <MenuItem value="2" disabled={isJornadaBlocked(2)}>Vespertina</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="dense" required>
            <InputLabel>Tipo de Servicio</InputLabel>
            <Select
              name="tipo_servicio"
              value={formData.tipo_servicio}
              onChange={handleChange}
              label="Tipo de Servicio"
            >
              <MenuItem value="1">Mnt Preventivo</MenuItem>
              <MenuItem value="2">Mnt Correctivo</MenuItem>
              <MenuItem value="3">Servicio Electrico</MenuItem>
            </Select>
          </FormControl>

          <div style={{ marginTop: 16 }}>
            <LoadScript googleMapsApiKey="">
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={formData.location || { lat: 0, lng: 0 }}
                zoom={10}
                onClick={handleMapClick}
              >
                {formData.location && <Marker position={formData.location} />}
              </GoogleMap>
            </LoadScript>
          </div>
          <Button onClick={getCurrentLocation} color="primary" style={{ marginTop: 16 }}>
            Busca mi ubicación
          </Button>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cerrar
            </Button>
            <Button type="submit" color="primary">
              Enviar
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
