import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

// Components
import PricingTable from "../Elements/PricingTable";
import Modal from "../Modal/RequestService";

export default function Pricing() {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedService, setSelectedService] = useState(0);

  const handleOpenModal = (service) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (formData) => {
    axios.post("https://api.airclean.store/servicio/RequestAppointment", formData)
      .then(response => {
        alert("Form submitted successfully");
        handleCloseModal();
      })
      .catch(error => {
        console.error("There was an error submitting the form!", error);
      });
  };

  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Mira Nuestros Servicios</h1>
            <p className="font13">
              <br />
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                // price="100.000 $COP"
                title="Mnt. Preventivo"
                text=""
                offers={[
                  { name: "Limpieza", cheked: true },
                  // { name: "Offer", cheked: true },
                  // { name: "Product Offer #2", cheked: false },
                  // { name: "Product", cheked: false },
                  // { name: "Product Offer", cheked: false },
                ]}
                action={() => handleOpenModal(1)} // ID para Mnt. Preventivo

              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                // price="100.000 $COP"
                title="mnt. Correctivo"
                text=""
                offers={[
                  { name: "Limpieza", cheked: true },
                  // { name: "Offer", cheked: true },
                  // { name: "Product Offer #2", cheked: true },
                  // { name: "Product", cheked: true },
                  // { name: "Product Offer", cheked: false },
                ]}
                action={() => handleOpenModal(2)} // ID para Mnt. Preventivo
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                // price="100.000 $COP"
                title="Servicio Electrico"
                text=""
                offers={[
                  { name: "Limpieza", cheked: true },
                  // { name: "Offer", cheked: true },
                  // { name: "Product Offer #2", cheked: true },
                  // { name: "Product", cheked: true },
                  // { name: "Product Offer", cheked: true },
                ]}
                action={() => handleOpenModal(3)} // ID para Mnt. Preventivo
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleSubmit} selectedService={selectedService} />

    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




